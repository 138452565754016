import React from 'react';

import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';

import { FlowComponentType } from 'routes/types';

import styles from './CardApplicationSuccessful.module.scss';

const CardApplicationSuccessful = ({ flags, handleNext, navigationInfo }: FlowComponentType) => (
  <StudentLoanLoaderWrapper flags={flags}>
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.mainContainer}>
        <div className={styles.cardContainer}>
          <div className={styles.iconContainer}>
            <CheckIcon />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.title}>Thanks for applying!</div>
            <div className={styles.description}>
              You are pre-qualified for Plannery Card! To secure your spot, please continue configuring your card.
            </div>
          </div>
          <Button type={ButtonType.Primary} onClick={handleNext}>
            Continue
          </Button>
        </div>

        <span className={styles.cardDisclaimerText}>
          Plannery Card qualification requirements may change when the card launches. Your application may need to be
          updated or re-evaluated.
        </span>
      </div>
    </>
  </StudentLoanLoaderWrapper>
);

export default CardApplicationSuccessful;
