import React, { useCallback } from 'react';
import clsx from 'clsx';

import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { LoanType, Source, Tradeline, TradeLineErrCode } from 'handlers/applicationData';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';

import Loader from 'components/Loader';

import { SelectedTradeline, TradelineNewState } from 'api/ApplicationDataApi';

import styles from './ConsolidationTable.module.scss';

interface ConsolidationTableProps {
  tradeLines: Tradeline[];
  selectedTradelines: Record<string, SelectedTradeline>;
  setSelectedTradelines: (selectedTradelines: Record<string, SelectedTradeline>) => void;
}

const ConsolidationTable = ({ tradeLines, selectedTradelines, setSelectedTradelines }: ConsolidationTableProps) => {
  const checkboxOnChange = useCallback(
    (selectedTradeline: SelectedTradeline) => {
      const { id } = selectedTradeline.tradeline;

      if (id in selectedTradelines) {
        const { [id]: omitted, ...rest } = selectedTradelines;
        setSelectedTradelines({ ...rest });
      } else {
        setSelectedTradelines({ ...selectedTradelines, [id]: selectedTradeline });
      }
    },
    [selectedTradelines, setSelectedTradelines],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={clsx(styles.column, styles.webHeaderColumn)} />
        <div className={styles.column}>Balance</div>
        <div className={styles.column}>Monthly Payment</div>
        <div className={styles.column}>Remaining Months</div>
        <div className={styles.column}>APR</div>
        <div className={clsx(styles.column, styles.webHeaderColumn)}>Savings</div>
      </div>

      <div className={styles.content}>
        {tradeLines.map((line, index) => (
          <TableRow
            key={line.id || `${line.firm?.trim()}-${line.balance}-${index}`}
            tradeLine={line}
            checkboxOnChange={checkboxOnChange}
            selected={
              convertTradelineNewStateToBoolean(selectedTradelines[line.id]?.newState) ??
              line.selectedForConsolidation ??
              false
            }
          />
        ))}
      </div>
    </div>
  );
};

const convertTradelineNewStateToBoolean = (newState: TradelineNewState | undefined) => {
  switch (newState) {
    case TradelineNewState.Selected:
      return true;
    case TradelineNewState.Unselected:
      return false;
    default:
      return undefined;
  }
};

interface TableRowProps {
  tradeLine: Tradeline;
  checkboxOnChange: (selectedTradeline: SelectedTradeline) => void;
  selected: boolean;
}

const maybeAddAnAsterisk = (errorCode: string[] | unknown, tradeline: Tradeline) => {
  if (tradeline.source === Source.CBC) {
    return '*';
  }

  if (tradeline.source === Source.Method && tradeline.loanType !== LoanType.CreditCard) {
    return '*';
  }

  if (errorCode && Array.isArray(errorCode) && errorCode.includes(TradeLineErrCode.EstimatedApr)) {
    return '*';
  }

  return '';
};

interface TradelineFirmProps {
  tradeLine: Tradeline;
  checkboxOnChange: (selectedTradeline: SelectedTradeline) => void;
  selected: boolean;
}

const TradelineFirm = ({ checkboxOnChange, tradeLine, selected }: TradelineFirmProps) => {
  const { firm, accountMask, loanType } = tradeLine;

  const formatType = (type: string) => type.split('-').join(' ');
  const formattedFirm = `${firm} - ${formatType(loanType)} ${accountMask ?? ''}`;

  const { isLoading } = useSelector((state: RootState) => state.applicationData);

  return (
    <>
      {isLoading ? (
        <div>
          <Loader color="#9d86f9" size={25} />
        </div>
      ) : (
        <CheckboxSmall
          onChange={() => {
            checkboxOnChange({
              tradeline: tradeLine,
              newState: selected ? TradelineNewState.Unselected : TradelineNewState.Selected,
            });
          }}
          checked={selected}
          label=""
          className={styles.checkbox}
          disabled={isLoading}
        />
      )}
      <div className={styles.creditorName}>{formattedFirm}</div>
    </>
  );
};

const TableRow = ({ tradeLine, checkboxOnChange, selected }: TableRowProps) => {
  const {
    savings,
    monthlyPaymentSavings,
    balance,
    balanceToConsolidate,
    monthlyPayment,
    remainingMonths,
    apr,
    errorCode,
    selectedForConsolidation,
  } = tradeLine;

  const selectedForPartialConsolidation = selectedForConsolidation && balanceToConsolidate !== balance;

  const savingsAmount = getSavingsAmountLabel(savings, monthlyPaymentSavings, remainingMonths);

  const showSavings = !!savingsAmount;

  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowHeading}>
        <div className={clsx(styles.group, !showSavings && styles.groupFullWidth)}>
          <TradelineFirm checkboxOnChange={checkboxOnChange} tradeLine={tradeLine} selected={selected} />
        </div>

        {showSavings && <div className={styles.saveAmount}>Save {savingsAmount}</div>}
      </div>
      {/* Partial balance for web version */}
      {selectedForPartialConsolidation && (
        <div
          className={clsx(
            styles.rowContent,
            styles.partialBalance,
            balanceToConsolidate === 0 && styles.partialBalanceZero,
          )}
        >
          <div className={clsx(styles.group, styles.groupFullWidth)}>
            {balanceToConsolidate === 0
              ? 'Cannot consolidate this debt'
              : `Partial payoff of ${formatMonetaryAmount(balanceToConsolidate!)}`}
          </div>
        </div>
      )}
      <div className={clsx(styles.rowContent, styles.mobileMargin)}>
        <div className={clsx(styles.group, styles.webContentColumn)}>
          <TradelineFirm checkboxOnChange={checkboxOnChange} tradeLine={tradeLine} selected={selected} />
        </div>

        <div aria-label="balance" className={styles.tableCol}>
          {formatMonetaryAmount(balance)}
        </div>
        <div aria-label="montlhy payment" className={styles.tableCol}>
          {formatMonetaryAmount(monthlyPayment!)}
        </div>
        <div aria-label="term" className={styles.tableCol}>
          {remainingMonths || 'N/A'}
        </div>
        <div aria-label="apr" className={styles.tableCol}>
          {apr ? `${apr}%` : 'N/A'}
          {maybeAddAnAsterisk(errorCode, tradeLine)}
        </div>

        {/* Savings column for web version */}

        <div className={clsx(styles.tableCol, styles.savings)}>{showSavings ? savingsAmount : '-'}</div>
      </div>
      {/* Partial balance for mobile version */}
      {selectedForPartialConsolidation && (
        <div
          className={clsx(
            styles.rowContent,
            styles.mobile,
            styles.partialBalance,
            balanceToConsolidate === 0 && styles.partialBalanceZero,
          )}
        >
          <div className={clsx(styles.group, styles.groupFullWidth)}>
            {balanceToConsolidate === 0
              ? `This exceeds the available balance`
              : `Partial payoff of ${formatMonetaryAmount(balanceToConsolidate!)}`}
          </div>
        </div>
      )}
    </div>
  );
};

const getSavingsAmountLabel = (
  savings: number | null | undefined,
  monthlyPaymentSavings: number | null | undefined,
  remainingMonths: number | null | undefined,
) => {
  savings = Math.max(savings ?? 0, 0);
  monthlyPaymentSavings = Math.max(monthlyPaymentSavings ?? 0, 0);

  if (!savings && !monthlyPaymentSavings) {
    return null;
  }

  if (savings > monthlyPaymentSavings * (remainingMonths ?? 0)) {
    return formatMonetaryAmount(savings);
  }

  return `${formatMonetaryAmount(monthlyPaymentSavings)}/mo`;
};

export default ConsolidationTable;
